<script setup>
// eslint-disable-next-line no-unused-vars
import {Spotlight} from 'spotlight.js';
import SectionTitle from "@/components/SectionTitle.vue";
import PhotoAlbum from "@/components/PhotoAlbum.vue";
import albums from '@/assets/json/albums.json'

console.log(albums)

console.log(Spotlight);
</script>

<template>
  <section>
    <div id="container">
      <SectionTitle color="dark" title="Gigs & Fotos:"/>
      <div class="album-container">

        <PhotoAlbum v-for="(album) in albums.albums"
                    :key="album.name"
                    :name="album['name']"
                    :date="album['date']"
                    :thumbnail="album['thumbnail_url']"
                    :content_urls="album['content_urls']"
        />

      </div>
    </div>
  </section>
</template>

<style scoped>

section {
  width: 100%;
  background: var(--bright);
}

#container {
  margin: auto; /* To center the text horizontally */
  max-width: 1000px; /* Limiting the width for extra large screens */
  padding: 50px 40px;
  background: var(--bright);
}

.album-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 5rem;
  justify-items: center;
}

/* For bigger screens*/
@media (min-width: 768px) {
  .album-container {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    row-gap: 3rem;
    justify-items: center;
  }
}
</style>