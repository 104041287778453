<script setup>
import {defineProps, computed} from "vue";

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  title: String,
  color: {
    type: String,
    default: "bright"
  }
});

const fontColor = computed(() => `color: var(--${props.color})`)
</script>

<template>
  <h3 class="section-title" :style="fontColor">{{ title }}</h3>
</template>

<style scoped>
.section-title {
  font-family: 'RockIt', sans-serif;
  font-size: 1.5rem;
  margin-bottom: 3rem;
}
</style>