<template>
  <div id="scroll">
    <a href="#content"><span></span></a>
  </div>
</template>

<script setup>

</script>

<style scoped>

#scroll a span {
  position: absolute;
  bottom: 75px;
  left: calc(50% - 50px / 2);
  width: 50px;
  height: 50px;
  border: 1px solid var(--bright);
  border-radius: 100%;
  box-sizing: border-box;
}

#scroll a span::after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: '';
  width: 16px;
  height: 16px;
  margin: -12px 0 0 -8px;
  border-left: 1px solid var(--bright);
  border-bottom: 1px solid var(--bright);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  box-sizing: border-box;
}

#scroll a span::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  content: '';
  width: 44px;
  height: 44px;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, .1);
  border-radius: 100%;
  opacity: 0;
  -webkit-animation: sdb03 2s infinite;
  animation: sdb03 2s infinite;
  box-sizing: border-box;
}

@-webkit-keyframes sdb03 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    box-shadow: 0 0 0 60px rgba(255, 255, 255, .1);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes sdb03 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    box-shadow: 0 0 0 60px rgba(255, 255, 255, .1);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
</style>