<script setup>

</script>

<template>
  <footer>
    <div>
      <strong>Impressum</strong>
      <address>
        <div>Tin God's Village Band</div>
        <div>Verantwortlicher: Philip Klaus</div>
        <div>Doktor-Heidrichgasse 2, 2434 Götzendorf an der Leitha, Österreich</div>
        <div>Telefon: +4369911048344</div>
        <div>Email: <a href="mailto:tingodsvillage@gmail.com">tingodsvillage@gmail.com</a></div>
      </address>
    </div>
    <div>
      Copyright © 2024 Philip Klaus | All rights reserved.
    </div>
    <div>
      This website uses <a href="https://github.com/nextapps-de/spotlight">spotlight.js</a> (licensed under the <a
        href="https://github.com/nextapps-de/spotlight?tab=Apache-2.0-1-ov-file#readme">Apache-2.0 license</a>)
    </div>
  </footer>
</template>

<style scoped>
footer {
  width: 100%;
  color: var(--dark);
  font-size: 0.75em;
  text-align: center;
  margin-top: 7em;
  padding-bottom: 1em;
  word-wrap: break-word;
}

a {
  cursor: pointer;
  text-decoration: underline;
  color: var(--dark);
}

/* unvisited link */
a:link {
  color: var(--dark);
}

/* visited link */
a:visited {
  color: var(--dark);
}

/* mouse over link */
a:hover {
  color: var(--dark);
}

/* selected link */
a:active {
  color: var(--dark);
}
</style>