<template>
  <article id="content">
    <SectionIntro/>
    <div class="band-image"/>
    <SectionMembers/>
    <SectionGigPhotos/>
    <SectionFooter/>
  </article>
</template>

<script>
import SectionIntro from "@/components/SectionIntro.vue";
import SectionMembers from "@/components/SectionMembers.vue";
import SectionGigPhotos from "@/components/SectionGigPhotos.vue";
import SectionFooter from "@/components/SectionFooter.vue";

export default {
  name: 'SiteContent',
  components: {SectionFooter, SectionGigPhotos, SectionMembers, SectionIntro}
}
</script>

<style scoped>

article {
  width: 100%;
  position: relative;
  z-index: 2;
  background: var(--bright);
  scroll-margin-top: 6.0rem;
}

.band-image {
  height: 250px;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      var(--dark)
  ), top / cover no-repeat url('../assets/hero.webp');

}

/* For bigger screens*/
@media (min-width: 768px) {
  .band-image {
    display: none;
  }
}

</style>
