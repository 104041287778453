<template>
  <section>
    <div id="members">
      <SectionTitle title="Die Band:"/>
      <div id="container">
        <MemberEntry v-for="member  in members()"
                     :key="member.name"
                     :image-url="member.imagePath"
                     :name="member.name"
                     :info-text="member.infoText"/>
      </div>
    </div>
  </section>
</template>

<script setup>
import MemberEntry from "@/components/MemberEntry.vue";
import {Profile} from "@/profile";
import SectionTitle from "@/components/SectionTitle.vue";


const members = () => {
  const publicPath = process.env.NODE_ENV === 'production' ? '/' : '/';
  return [
    new Profile(
        publicPath + "members/tanya.webp",
        "Tanya Markowitsch",
        "Hallo zusammen! Ich bin Tanya, die Sängerin. Ich singe, solange ich denken kann, und das ist etwas, " +
        "das ich mit unglaublicher Leidenschaft tue. Musik ist meine ganze Welt und ich liebe es, meine Zeit mit " +
        "Singen und Komponieren zu verbringen. Ich habe in vielen Bands gespielt (meiner ersten trat ich im Alter " +
        "von 13 Jahren bei) und bin auch als Solosängerin tätig." +
        "Ich liebe alle Arten von Musik, aber mein Ziel ist es, Musik zu schreiben, die von Herzen kommt. Zum Glück " +
        "kann ich das mit Tin God's Village tun!"
    ),
    new Profile(
        publicPath + "members/cara.webp",
        "Cara Elena Weber",
        "Ich bin nicht nur das Lärcherl der Gruppe, sondern auch das Küken.\n" +
        "Seit meiner frühsten Kindheit habe ich einen engen Bezug zu Musik und auch schon einige Instrumente durch. \n" +
        "Doch das Singen war schon immer meine größte Leidenschaft.\n" +
        "Bereits damals war es mein größter Traum in einer Band zu singen.\n" +
        "Ich schreibe auch sehr gerne Texte. Und gemeinsam verwandeln wir bei TGV  meine niedergeschriebenen " +
        "Hirngespinnste, in Songs mit hohem Ohrwurmpotenzial."
    ),
    new Profile(
        publicPath + "members/mike.webp",
        "Michael Skob",
        "Hi, I‘m Mike, the man on the guitar. Mache mein ganzes Leben schon Musik und kann auch ohne nicht " +
        "leben. Sie ist mein mentaler Ausgleich und mein kreative Pol. Ausleben kann ich diesen am besten beim " +
        "Komponieren von eigener Musik. Meine Vorliebe gilt der Prog- und Rock-Musik jedoch bin ich offen für fast " +
        "alles. Denn, es gibt keine musikalischen Grenzen. Und das macht TinGodsVillage aus."
    ),
    new Profile(
        publicPath + "members/helmut.webp",
        "Helmut Boeheim",
        "Ich bin der Tastenmann der Band. Warum? " +
        "Weil ich es liebe mit Sounds zu experimentieren, aber auch klassische Instrumente zu kombinieren, und diese " +
        "in noch nie gehörtes Liedgut zu integrieren. Ich spiele mehrere Instrumente, aber mit Keyboards ist das am " +
        "besten zu realisieren. Musik ist überall, allgegenwärtig, für alle Lebenslagen und Stimmungen nutzbar. " +
        "Und das beste ist, sie kann immer wieder neu erfunden werden - und genau das tun wir mit TGV."
    ),
    new Profile(
        publicPath + "members/tereza.webp",
        "Tereza Traxler",
        "Hey ich heiße Tereza, bin aus Prag und habe in Tin Gods Village mein neues Zuhause gefunden. " +
        "Ich spiele Bass und versuche die tiefen Töne immer höher zu spielen, was mörderisch viel Spaß macht - denn: " +
        "'Music is my life, my life is music...'"
    ),
    new Profile(
        publicPath + "members/philip.webp",
        "Philip Klaus",
        "Hi, ich bin Phil und der Lauteste in der Band! Schon als kleiner Junge spielte ich 'Schlagzeug' auf " +
        "den Kochtöpfen meiner Mutter. Ich trommle immer und überall und treibe mein Umfeld damit manchmal in den " +
        "Wahnsinn. In Tin God's Village kann ich mich daher austoben und musikalisch völlig entfalten."
    )
  ]
}
</script>


<style scoped>

section {
  width: 100%;
  background: var(--dark);
}

#members {
  margin: auto; /* To center the text horizontally */
  max-width: 1000px; /* Limiting the width for extra large screens */
  padding: 50px 40px;
  background: var(--dark);
}

#container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 5rem;
  row-gap: 1rem;
}


</style>