<template>
  <nav>
    <ul class="ct-socials">
      <li>
        <a href="https://www.instagram.com/tingodsvillage/" target="_blank">
          <font-awesome-icon icon="fa-brands fa-instagram"/>
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/profile.php?id=61551516923576" target="_blank">
          <font-awesome-icon icon="fa-brands fa-facebook-f"/>
        </a>
      </li>
      <li>
        <a href="mailto:tingodsvillage@gmail.com">
          <font-awesome-icon icon="fa-solid fa-envelope"/>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script setup>

</script>

<style scoped>
.ct-socials {
  font-size: 1.3em;
  position: fixed;
  top: 25%;
  right: 0;
  list-style: none;
  padding-left: 0;
  z-index: 10;
  margin: 0;
  -webkit-transition: right 0.25s ease-in-out;
  transition: right 0.25s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.ct-socials li {
  padding: 0px 0;
}

.ct-socials li a {
  background: #3f4653;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--bright);
  width: 35px;
  height: 50px;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  float: right;
  padding-left: 2px;
}

.ct-socials li a:hover {
  width: 54px;
  padding-left: 0;
  text-decoration: none;
  background: #3c9bd9;
}

</style>